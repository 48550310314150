import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API Absensi */

export function getAbsenTodayByNIK(usernik, today) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/vabsens/m/today?nik=' + usernik + '&jam_masuk=' + today
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getAbsenCosec(nik) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/vabsens/cosec/' + nik
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`

    },
    data
  })
  // console.log(response)
  return response
}

export function getListAbsensiByNIK(nik, dStart, dEnd) {
  // console.log(nik)
  if (nik == '' || nik == null) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/vabsens/m/' + nik + '/' + dStart + '/' + dEnd
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertAbsensi(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/vabsens'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function deleteAbsensi(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/vabsens/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateAbsensi(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/vabsens/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countAbsensi(listQuery) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/vabsens/count'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API PenomoranSrt ==== */


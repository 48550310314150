<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >
      
      <base-material-tabs
          v-model="tab"

          color="warning"
          icons-and-text
        >
      <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
          >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

        <v-tabs-items
          v-model="tab"
          class="pt-5 transparent"
        >

          <v-tab-item>
            <v-container>
                  
                <v-row dense>
                  <v-col cols="12" md="6" sm="6">
                   
                    <v-card
                          class="mx-auto"
                          color="#1F7087"
                          dark
                        >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="headline mb-4  font-weight-bold text-center">ABSEN MASUK</div>
                            <v-list-item-title class="headline mb-1 white--text text-left">{{todayDate | formatToday}}</v-list-item-title>
                            <v-list-item-title class="display-4 mb-4 yellow--text font-weight-bold text-right">{{timeIn}}</v-list-item-title>
                            <v-list-item-subtitle class="text-right">&nbsp;</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                    </v-card>
                  </v-col>
                  <v-col cols="12"  md="6" sm="6">
                    <v-card
                          class="mx-auto"
                          color="#952175"
                          dark
                        >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="headline mb-4  font-weight-bold text-center">ABSEN PULANG</div>
                            <v-list-item-title class="headline mb-1  white--text text-left">{{todayDate | formatToday}}</v-list-item-title>
                            <v-list-item-title class="display-4 mb-4 yellow--text font-weight-bold text-right">{{timeOut}}</v-list-item-title>
                            <v-list-item-subtitle class="text-right">&nbsp;</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12">
                    <div class="mb-4 text-center"><v-btn @click="getAbsenTodayfromCosec()">Refresh <v-icon right dark>mdi-refresh</v-icon></v-btn></div>
                  </v-col>
                </v-row>

              </v-container>
          </v-tab-item>
          <v-tab-item>
            <base-material-card
              icon="mdi-clipboard-text"
              title="List Data Absen Bulanan"
              class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                      <v-dialog
                          ref="SelMonth"
                          v-model="menuSelMonth"
                          :return-value.sync="selectedMonth"
                          persistent
                          width="290px"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="selectedMonth"
                            label="Pilih Bulan/Tahun"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedMonth" type="month" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuSelMonth = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.SelMonth.save(selectedMonth)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="getListAbsen()">Show Data</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      &nbsp;
                    </v-col>
                  </v-row>

                  <v-data-table
                      :headers="headers"
                      :items="listData"
                      :items-per-page="30"
                      class="elevation-1"
                      @selected="getListAbsen()"
                    >
                    <template v-slot:item.tglKet="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.tglKet[1]" color="warning" v-on="on" fab x-small @click="showUploadDialog(item.tglKet)" >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn v-else color="primary" v-on="on" fab x-small @click="showUploadDialog(item.tglKet)" >
                            <v-icon>mdi-upload</v-icon>
                          </v-btn>
                        </template>
                        <span>Lihat Ket</span>
                      </v-tooltip>
 
                    </template>
                  </v-data-table>
  
                  <v-alert
                  border="left"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  <p class="subtitle-1">Keterangan:</p>
                  <div>*Mohon abaikan PSW4 pada tanggal yang sama, dikarenakan sistem belum me-record data absen pada mesin absen.</div>
                </v-alert>
                </base-material-card>
              </v-tab-item>

            </v-tabs-items>
        
        </base-material-tabs>

      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="saveKeteranganAbsenForm()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogKetAbsen" persistent max-width="80vw">
        <v-card
          justify-center align-center
        >
          <v-card-title class="headline">Keterangan Absen</v-card-title>
          <v-card-text>
            
            <v-form ref="form" v-model="valid">
              <v-card
                class="pa-md-4 mx-lg-auto"
                outlined
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                  <v-textarea
                      v-model="absenKetForm.keterangan"
                      label="Keterangan Absen"
                      placeholder="Isi keterangan secara lengkap dan jelas"
                      auto-grow
                      :rules="[rules.required]"
                      rows="2"
                      row-height="25"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-file-input
                      v-model="attachment.file"
                      placeholder="Upload Keterangan Absen"
                      label="File Upload"
                      show-size
                      single
                      prepend-icon="mdi-paperclip"
                      accept="image/*,.pdf"
                      @change="onFileChange"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          small
                          label
                          color="primary"
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <a v-if="attachment.url" :href="attachment.url" target="_BLANK">Buka File</a>
                    <a v-else href="#">Tidak ada file attachment</a>
                  </v-col>

                </v-row>

              </v-card>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialogKetAbsen = false">Batal</v-btn>
            <v-btn color="primary" @click="saveAddForm()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    
  </v-container>
</template>

<script>
  import secureStorage from '@/utils/secureStorage'
  import moment from 'moment'
  import axios from 'axios'
  import { getAbsenTodayByNIK, getAbsenCosec, getListAbsensiByNIK } from '@/api/vabsensi'
  import { getAbsenKeterangan, insertAbsenKet, updateAbsenKet } from '@/api/vabsen_keterangan'
  import { getMasterAttachment, insertMasterAttachment, uploadMasterAttachment, deleteMasterAttachment, deleteFileMasterAttachment } from '@/api/masterattachment'

  import { _GLOBAL } from '@/api/server'

  const VAbsenKeteranganForm = {
    tanggal: '0000-00-00',
    nik: '',
    keterangan: '',
  }

  export default {
    name: 'Absensi',

    data: () => ({
      absenKetForm: Object.assign({}, VAbsenKeteranganForm),
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
      },
       attachment: {
        name: '',
        file: null,
        url: ''
      },
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'Absensi Hari ini',
          icon: 'mdi-information',
          },
          {
            text: 'Rekap Absensi Bulanan',
            icon: 'mdi-information',
          }
      ],
      headers: [
        {
          text: 'NO',
          align: 'start',
          class:'subtitle-1',
          sortable: false,
          value: 'no',
        },
        { text: 'TANGGAL', value: 'ur_tgl_absen', align: 'center', class:'subtitle-1' },
        { text: 'JAM MASUK', value: 'jam_masuk', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'JAM PULANG', value: 'jam_pulang', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'JAM KERJA ( -1 Jam Istirahat )', value: 'jam_kerja', align: 'center', class:'subtitle-1' },
        { text: 'Status TL', value: 'stsTL', align: 'center', class:'subtitle-1' },
        { text: 'Status PSW', value: 'stsPSW', align: 'center', class:'subtitle-1'},
        { text: 'TIDAK HADIR', value: 'tdkHadir', align: 'center', class:'subtitle-1'},
        { text: 'HADIR', value: 'Hadir', align: 'center', class:'subtitle-1'},
        { text: 'DINAS LUAR', value: 'DinLuar', align: 'center', class:'subtitle-1'},
        { text: 'Cuti', value: 'Cuti', align: 'center', class:'subtitle-1'},
        { text: 'KET', value: 'tglKet', align: 'center', class:'subtitle-1'},
      ],
      isLoading: false,
      absenIn: '-',
      absenOut: '-',
      listData: [],
      totalData: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 30,
        search: ''
      },
      selectedMonth: new Date().toISOString().substr(0, 7),
      menuSelMonth: false,
      isEdit: false,
      dialogConfirm: false, 
      isupLoading: false,
      uploadPercentage: 0,
      dialogKetAbsen: false,
      isabsenKet: false,
      userNIK: '',
      todayDate: '',
      timeIn: '00:00:00',
      timeOut: '-',

    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      },
      formatToday: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      },
      formatHOUR: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('HH:mm:ss')
        return sdate
      },
    },

    created () {
      const vm = this

      vm.dataUser = secureStorage.getItem('user')
      // console.log(vm.dataUser)
      vm.isAdmin = false
      if (vm.dataUser !== null) {
        vm.userNIK = vm.dataUser.nik
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      vm.getAbsenToday()
    },
    watch: {
      tableOpt: {
        handler () {
          this.getListAbsen()
        },
        deep: true,
      },
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      resetForm () {
        this.absenKetForm.keterangan = ''
        this.attachment = { name: '', file: null, url: '' }
      },
      getAbsenToday() {
        var vm = this
        if(vm.userNIK == '') {
          // console.log('vm.userNIK empty')
          return false
        }
        vm.todayDate = moment().format('YYYY-MM-DD')
        // vm.todayDate = '2020-03-03' // TESTING ONLY TO SPECIFIC DATE
        
        vm.isLoading = true
        // console.log('getAbsenToday',vm.userNIK)
        getAbsenTodayByNIK(vm.userNIK, vm.todayDate).then(response => {
          var array = response.data
          // console.log(array)
          if (array.length > 0) {
            vm.parseData(array[0])
          } else {
            // console.log('get data cosec')
            vm.getAbsenTodayfromCosec()
          }
          vm.isLoading = false
        })
        .catch(function(err){
          // console.log(err)
          vm.isLoading = false
        })
        
      },
      async getAbsenTodayfromCosec() {
        const vm = this
        // console.log('getAbsenTodayfromCosec')
        if(vm.userNIK == '') {
          // console.log('vm.userNIK empty')
          return false
        }

        vm.isLoading = true
        // console.log(vm.userNIK)
        getAbsenCosec(vm.userNIK).then(response => {
          var array = response.data
          if (array != null) {
            vm.parseData(array)
          }
          vm.isLoading = false
        })
        .catch(function(err){
          // console.log(err)
          vm.isLoading = false
        })
      },
      parseData(data) {
        const vm = this
        if (data != null) {
          // console.log(data)
          var inTime = data.jam_masuk ? data.jam_masuk : '-'
          var outTime = data.jam_pulang? data.jam_pulang : '-'

          if (inTime != null && outTime != '') {
            if(moment(inTime).isValid()){
              vm.timeIn = moment(inTime).format('HH:mm:ss')
            }else{
              vm.timeIn = '-'
            }
          } else {
            vm.timeIn = '-'
          }

          if (outTime != null && outTime != '') {
            if(moment(outTime).isValid()){
              vm.timeOut = moment(outTime).format('HH:mm:ss')
            }else{
              vm.timeOut = '-'
            }
          } else {
            vm.timeOut = '-'
          }

          // console.log('inTime: ', vm.timeIn)
          // console.log('timeout: ', vm.timeOut)
          /* caused looping
          if(vm.timeOut == '-' || vm.timeOut == '00:00:00'){
            var now = moment().format('HH:mm:ss')
            const mindiff = moment(now, 'HH:mm:ss').diff(moment(vm.timeIn, 'HH:mm:ss'), 'hours')
            // console.log(mindiff)
            if(mindiff > 5 ){
              // vm.getAbsenTodayfromCosec()
            }
          }*/
        }
      },
      getListAbsen() {
        const vm = this
        if(vm.userNIK == '') {
          // console.log('vm.userNIK empty')
          return false
        }
       
        // console.log(vm.userNIK)
        var start = vm.selectedMonth + '-01'
        var send = vm.selectedMonth
        var enddate = moment(send).endOf('month').format('DD')
        var end = vm.selectedMonth + '-' + enddate
        
        vm.resetForm()
        vm.getListKeterangan(start, end)
      },
      getListKeterangan(start, end) {
        const vm = this

        if(vm.userNIK == '') {
          // console.log('vm.userNIK empty')
          return false
        }

        vm.isLoading = true
        getAbsenKeterangan(vm.userNIK, start, end).then(response => {
          var dataKet = response.data
          // console.log(dataKet)
          if (dataKet) {
            vm.arrlistKeterangan = dataKet
          }
          vm.isLoading = true
          getListAbsensiByNIK(vm.userNIK, start, end).then(response => {
            var array = response.data
            // console.log(array)
            if (array != null) {
              var temp = []
              var i = 1
              array.forEach(element => {
                if (element.jam_masuk == null) {
                  element.jam_masuk = '-'
                }

                if (element.jam_pulang == null) {
                  element.jam_pulang = '-'
                }

                var jam_kerja = '~'
                if (element.jam_masuk != '-' && element.jam_pulang != '-') {
                  var ms = moment(element.jam_pulang, 'HH:mm').diff(moment(element.jam_masuk, 'HH:mm'))
                  var d = moment.duration(ms)
                  var afd = parseInt(d.asHours()) - 1
                  if (!isNaN(afd)) {
                    jam_kerja = Math.floor(afd) + ' Jam ' + moment.utc(ms).format('mm') + ' Menit'
                  }
                }
                element.jam_kerja = jam_kerja

                element.bolNeedEdit = false
                element.stsTL = '-'
                if (element.TL1 == 1) {
                  element.stsTL = 'TL1'
                  element.bolNeedEdit = true
                } else {
                  if (element.TL2 == 1) {
                    element.stsTL = 'TL2'
                    element.bolNeedEdit = true
                  } else {
                    if (element.TL3 == 1) {
                      element.stsTL = 'TL3'
                      element.bolNeedEdit = true
                    } else {
                      if (element.TL4 == 1) {
                        element.stsTL = 'TL4'
                        element.bolNeedEdit = true
                      }
                    }
                  }
                }

                element.stsPSW = '-'
                if (element.PSW1 == 1) {
                  element.stsPSW = 'PSW1'
                  element.bolNeedEdit = true
                } else {
                  if (element.PSW2 == 1) {
                    element.stsPSW = 'PSW2'
                    element.bolNeedEdit = true
                  } else {
                    if (element.PSW3 == 1) {
                      element.stsPSW = 'PSW3'
                      element.bolNeedEdit = true
                    } else {
                      if (element.PSW4 == 1) {
                        element.stsPSW = 'PSW4'
                        element.bolNeedEdit = true
                      }
                    }
                  }
                }

                element.tdkHadir = '-'
                if (element.X == 1) {
                  element.tdkHadir = element.X
                  element.bolNeedEdit = true
                } else {
                  element.tdkHadir = element.X
                }

                element.Hadir = '-'
                if (element.h == 1) {
                  element.Hadir = element.h
                } else {
                  element.Hadir = element.h
                }

                element.DinLuar = '-'
                if (element.DL == 1) {
                  element.DinLuar = element.DL
                } else {
                  element.DinLuar = element.DL
                }

                element.Cuti = '-'
                if (element.CT == 1) {
                  element.Cuti = element.CT
                } else {
                  element.Cuti = element.CT
                }

                var tgl_absen = moment(element.tgl_absen).format('YYYY-MM-DD')
                var hasKet = false
                
                dataKet.forEach(elKet => {
                  var tgl = moment(elKet.tanggal).format('YYYY-MM-DD')
                  if(tgl == tgl_absen){
                    hasKet = true
                  }
                });
                var tempKet = []
                tempKet.push(tgl_absen)
                tempKet.push(hasKet)
                element.tglKet = tempKet
                // console.log(element.tglKet)

                element.no = i
                i++
              })
              vm.listData = array
            }
            vm.isLoading = false
          }).catch(function(err){
            // console.log(err)
            vm.isLoading = false
          });
        }).catch(function(err){
          // console.log(err)
          vm.isLoading = false
        });
      },
      saveAddForm () {
        const vm = this
        
        if(vm.$refs.form.validate()){
          var add7 = moment(vm.absenKetForm.tanggal).add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
          vm.absenKetForm.tanggal = add7

          // console.log(vm.absenKetForm)
          vm.dialogConfirm = true
        }
        else{
          vm.toast = {
            show: true, color:'red', text: 'Form still has an error!', timeout: 2000
          }
        }
      },       
      saveKeteranganAbsenForm() {
        const vm = this
        vm.dialogConfirm = false
        vm.dialogKetAbsen = false

        if (!vm.isabsenKet) {
          delete vm.absenKetForm.id
          insertAbsenKet(vm.absenKetForm).then(responseK => {
            var data = responseK.data
            // console.log(respL)
            vm.absenKetForm = data
            if (vm.attachment.file !== null) {
              vm.uploadAttachment()
            }
            vm.isLoading = false
            vm.getListAbsen()
            vm.toast = {
              show: true, color:'green', text: 'Submit Keterangan Absen TGL: ' + moment(vm.absenKetForm.tanggal).format('DD MMM YYYY') + ' telah BERHASIL.', timeout: 2500
            }
          })
          .catch(err => {
            // console.log(err)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          })
        } else {
          var idb = vm.absenKetForm.id
          updateAbsenKet(vm.absenKetForm, idb).then(responseK => {
            var data = responseK.data
            // console.log(respK)
            vm.absenKetForm = data
            if (vm.attachment.file !== null) {
              vm.uploadAttachment()
            }
            vm.getListAbsen()
            vm.isLoading = false
            vm.toast = {
              show: true, color:'green', text: 'Update Keterangan Absen TGL: ' + moment(vm.absenKetForm.tanggal).format('DD MMM YYYY') + ' telah BERHASIL.', timeout: 2500
            }
          })
          .catch(err => {
            // console.log(err)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          })
        }
      },
      showUploadDialog(data) {
        const vm = this

        vm.resetForm()
        if (vm.dataUser !== null) {
          vm.absenKetForm.nik = vm.dataUser.nik
        }else{
          return false
        }
        vm.isabsenKet = false
        // console.log(data[0])
        if(moment(data[0]).isValid()){
          var sDate = moment(data[0]).format('YYYY-MM-DD')
          vm.absenKetForm.tanggal = sDate

          var array = vm.arrlistKeterangan
          // console.log(array)
          array.forEach(element => {
            var dbDate = moment(element.tanggal).format('YYYY-MM-DD')
            if (dbDate == sDate) {
              // console.log(element)
              vm.absenKetForm.id = element.id
              vm.absenKetForm.keterangan = element.keterangan
              if (element.adk_lampiran !== null){
                vm.attachment.url = _GLOBAL.URLBACKEND + element.adk_lampiran.url
              }
              else{
                vm.attachment.url = null
              }
              vm.isabsenKet = true
              return true
            }
          })
          vm.dialogKetAbsen = true
        }else{
          vm.$message({
            message: 'Data tanggal null',
            type: 'danger'
          })
        }
      },
      beforeFileUpload (file) {
      
        var isFormatOK = false
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
          isFormatOK = true
        }
        const isLt2M = file.size / 1024 / 1024 < 10
        if (!isFormatOK) {
          this.$message.error('Avatar picture must be JPG format!')
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 10MB!')
        }
        return isFormatOK && isLt2M
      },
      onFileChange () {
        const file = this.attachment.file
        if(this.attachment.file){
          this.attachment.url = URL.createObjectURL(file)
        }
      },
      addAttachment (file, fileList) {
        this.attachment.file = file
        // console.log(this.attachment.file)
      },
      uploadAttachment () {
        const vm = this
        // console.log('uploadAttachment',vm.attachment)
        if (vm.attachment.file) {
          var file = vm.attachment.file
          if (vm.beforeFileUpload(file)) {
            if (vm.absenKetForm.id !== 0) {
              vm.uploadFileAttachment(vm.absenKetForm.id, file)
            }
          }
        }
      },
      uploadFileAttachment (refID, file) {
        const vm = this
        // console.log('uploadFileAttachment')
        var token = localStorage.getItem('jwt')
        if (token.length === 0) return null

        const bodyFormData = new FormData()
        bodyFormData.append('refId', refID)
        bodyFormData.append('field', 'adk_lampiran')
        bodyFormData.append('ref', 'Vabsenketerangan')
        bodyFormData.append('path', 'Vabsenketerangan/adk_lampiran')
        // Add each file upload to the form data
        bodyFormData.append('files', file, file.name)
        // console.log(file.name)
        var uri = _GLOBAL.URLBACKEND + '/upload'
        
        vm.isupLoading = true
        vm.uploadPercentage = 0
        axios.post(uri,
          bodyFormData,
          {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            onUploadProgress: function( progressEvent ) {
              vm.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
            }.bind(vm)
          }
        ).then(function(response){
          // console.log('SUCCESS!!');
          // console.log(response.data)

          vm.isupLoading = false
          vm.toast = {
            show: true, color:'green', text: 'File Keterangan Absen telah BERHASIL tersimpan.', timeout: 2000
          }
        })
        .catch(function(err){
          // console.log('FAILURE!!');
          // console.log(err)
            vm.isupLoading = false
            vm.toast = {
              show: true, color:'red', text: 'File Keterangan Absen GAGAL tersimpan, silakan dicoba kembali.', timeout: 2000
            }
        });
      }
    }
  }
</script>
